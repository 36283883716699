@mixin table-shadow() {
  position: relative;
  background-color: $table-header-bg !important;
  background-clip: border-box;
  border-radius: $table-border-radius;

  .table {
    border: 1px solid $table-header-bg;
    border-radius: $table-border-radius;
    overflow: hidden;

    thead {

      tr {

        th {
          padding-top: 12px;
          padding-bottom: 12px;

          &:first-of-type {
            padding-left: $table-border-radius;
          }

          &:last-of-type {
            padding-right: $table-border-radius;
          }
        }
      }
    }

    tbody {
      border-top-left-radius: $table-border-radius;
      border-top-right-radius: $table-border-radius;

      tr {
        background: $table-body-bg !important;

        &:first-child {

          td {
            position: relative;
            padding: 0.5rem 1rem;
          }

          td:first-child,
          td:last-child {

            &::before,
            &::after {
              position: absolute;
              transition: all 0.15s ease-in-out;
              height: $table-border-radius * 2;
              width: $table-border-radius;
              box-shadow: 0 ($table-border-radius * -1) 0 0 $table-header-bg;
            }
          }

          td:first-child {

            &::before {
              content: '';
              top: 0;
              left: 0;
              border-top-left-radius: $table-border-radius;
            }
          }

          td:last-child {

            &::after {
              content: '';
              top: 0;
              right: 0;
              border-top-right-radius: $table-border-radius;
            }
          }
        }
      }
    }
  }

  @if $enable-shadows {
    // @include box-shadow($btn-box-shadow, 0 10px 30px 5px rgba($black, .85));
  }
}

@mixin table-list-view() {

  .table {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      @include table-shadow();

      display: flex;
      flex-flow: column wrap;
      margin-bottom: 2rem;

      td {
        border-top: none;
        border-bottom: none;
        flex-basis: auto;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;

        td {
          flex-basis: 50%;
        }
      }

      @include media-breakpoint-up(lg) {

        td {
          flex-basis: 33.3%;
        }
      }

      @include media-breakpoint-up(xxxl) {

        td {
          flex-basis: 25%;
        }
      }
    }
  }
}

.table-wrapper {
  @include table-shadow();

  overflow-y: hidden;
  overflow-x: scroll;
}

.table-wrapper-responsive {
  position: relative;

  @include border-radius($table-border-radius);

  .list-view-heading {
    font-weight: bold;
  }

  &:not(.list-view) {
    @include media-breakpoint-up(lg) {
      overflow-y: hidden;
      overflow-x: scroll;

      .list-view-heading {
        display: none;
      }

      @include table-shadow();
    }
  }

  &.list-view {
    @include table-list-view();
  }

  @include media-breakpoint-down(lg) {
    @include table-list-view();

    .table td {
      padding: 0.25rem 0.75rem;
    }
  }
}

.table > tbody > tr:last-child > td {
  border-bottom: none;
}

.table.table-hover > tbody > tr:hover {

  .tx-primary {
    color: $table-header-bg !important;
  }
}
