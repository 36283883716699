.switch-field {
  display: inline-block;
  overflow: visible;
  background-color: $card-plate-bg;
  border-radius: 20px;
  height: 40px;
  line-height: 0;
  white-space: nowrap;
}

.switch-field input {
  position: absolute !important;
  height: 0;
  width: 0;
  border: 0;
  visibility: hidden;
  overflow: hidden;
}

.switch-field label {
  border-radius: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 11px 20px;
  transition: all 0.1s ease-in-out;
  height: 40px;

  &:hover {
    cursor: pointer;
  }
}

.switch-field input:checked + label {
  border-radius: 30px;
  background: linear-gradient(87.16deg, #8D6B00 -15.86%, #EAD177 122.34%);
  box-shadow: none;
  padding: 13px 20px;
  margin-top: -2px;
  z-index: 1;
  height: 44px;
  cursor: default;
}

.switch-field.only-icons label {
  font-size: 20px;
}
