#staking {

  .heading {
    max-width: 700px;
    width: 100%;
  }

  .subheading {
    max-width: 400px;
    width: 100%;
  }

  .card-block {

    .label {
      font-weight: 600;
    }

    .value {
      color: $gray-500;
    }
  }

  .invest-block {
    width: 100%;
    max-width: 500px;
  }

  .gat-plan-select {
    border: none;
    font-weight: bold;
  }

  .ethi-package-select {
    padding-left: 0;
    padding-right: 0;
    border: none;
    font-weight: bold;
    border-radius: 0;
  }
}
