#promo-actions {
  display: block;

  .image-block {
    padding: 18px;
    width: 100%;
    min-width: 400px;

    img {
      display: inline-block;
      width: fit-content;
    }
  }

  .details-block {
    width: 100%;
    min-width: 350px;
  }
}

@include media-breakpoint-down(sm) {

  #promo-actions {

    .image-block {
      min-width: 250px;
    }

    .details-block {
      min-width: 250px;
    }
  }
}
