@import '@~styles/custom-variables.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $body-bg;

  &.logged-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}
