@import '@~styles/custom-variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.partnersList {

  :global(.card) {
    flex-basis: 150px;
    max-width: 150px;
    background-color: $black;
    border: 1px solid transparent;

    img {
      max-height: 60px;
    }

    @include media-breakpoint-up(md) {
      flex-basis: 220px;
      max-width: 220px;

      img {
        max-height: 80px;
      }
    }
  }
}
