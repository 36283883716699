* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-size: 16px;
  color: $body-color;
  background-color: $body-bg;
}

body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  color: $body-color;
  background-color: $body-bg;
  font-family: $font-family !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.page {
  position: relative;
  margin-top: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.5;
}

::selection {
  background: $selection-bg;
  color: $selection-color;
}
